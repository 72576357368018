import React from "react";

import Page from 'containers/page';
import Contact from 'containers/Contact';


export default () => {
  return <Page activeItemId="contact" seoProps={{
    title: "kontaktiere uns",
  }}>
    <Contact/>
  </Page>
}